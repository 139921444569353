import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Empty from '../../../Components/Empty';
import ProductItemType1 from './ProductItemType1';
import ArticleItemType1 from './ArticleItemType1';

function ProductGrid(props) {
  const {products, onItemClick, prefixPath} = props;
  const [categroies] = useOutlet('categories');
  const [articleCategories] = useOutlet('articleCategories');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  if (products.length <= 0) {
    return <Empty />;
  }

  if (
    prefixPath.indexOf('products') >= 0 ||
    prefixPath.indexOf('sales-event') >= 0
  ) {
    return (
      <ProductGridWrapper mobile={mobile} className="ProductGrid">
        {products.map((product, i) => {
          if (categroies.meta_data.item_ui_type === 'type1') {
            return (
              <ProductItemType1
                key={product.id}
                mobile={mobile}
                product={product}
                onClick={(evt) => onItemClick(product, evt)}
              />
            );
          }

          return (
            <ProductItemType1
              key={product.id}
              mobile={mobile}
              product={product}
              onClick={(evt) => onItemClick(product, evt)}
            />
          );
        })}
      </ProductGridWrapper>
    );
  } else if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper mobile={mobile} className="ArticleGrid">
        {products.map((product, i) => {
          if (articleCategories.meta_data.item_ui_type === 'type1') {
            return <ArticleItemType1 key={i} item={product} />;
          }

          return <ArticleItemType1 key={i} item={product} />;
        })}
      </ArticleGridWrapper>
    );
  }
}

const ProductGridWrapper = styled.div`
  padding: var(--basePadding);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

  @media screen and (max-width: ${768 - 1}px) {
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
`;

const ArticleGridWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 20px;

  @media screen and (max-width: ${768 - 1}px) {
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
`;

export default ProductGrid;
